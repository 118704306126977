import WsProvider from './provider/WSProvider'
import axios from "axios";

const serviceName = "webservis";
const moduleName = "galeri";

var galeriService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    galeriListeleDurumIle(durum, baslangic, limit) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "galeriListele"
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result;
    },
    galeriSayisi(durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            durum: durum,
            serviceName: moduleName,
            methodName: "galeriSayisi"
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result;
    },

    galeriDurumDegis(galeriID, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            galeriID,
            durum,
            serviceName: moduleName,
            methodName: "galeriAktifPasifYap"
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    galeriPasifYap(galeriID) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            galeriID: galeriID,
            durum: 0,
            serviceName: moduleName,
            methodName: "galeriAktifPasifYap"
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    galeriAktifYap(galeriID) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            galeriID: galeriID,
            durum: 1,
            serviceName: moduleName,
            methodName: "galeriAktifPasifYap"
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    galeriResimSil(link, resim) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            link: link,
            galeriResim: resim,
            serviceName: moduleName,
            methodName: "galeriResimSil"
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    //$data['baslik'],$upload['dosyaAdi'],$data['link'], $data['durum'], $simdi, $yetkili, $ip1, $ip2, $ip3, "panel"
    galeriEkle(baslik, resim, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            baslik: baslik,
            resim: resim,
            durum: durum,
            serviceName: moduleName,
            methodName: "galeriEkle"
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    galeriDetay(galeriID) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            galeriID: galeriID,
            serviceName: moduleName,
            methodName: "galeriDetay"
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    //$data['galeriID'],$data['baslik'],$upload['dosyaAdi'],
    galeriGuncelle(galeriID, baslik, resim, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            galeriID: galeriID,
            baslik: baslik,
            resim: resim,
            durum: durum,
            serviceName: moduleName,
            methodName: "galeriGuncelle"
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;

    }


}

export default galeriService