<template>
    <div class="page-table haberTanim  mainDiv" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">
            {{$t("src.views.apps.galeri.define.title")}}
        </div>

        <el-row :gutter="10" v-loading="loading" :element-loading-text='$t("src.views.apps.galeri.define.addLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="galeriForm" ref="galeriForm" :rules="rulesGaleri" class="demo-ruleForm"> <!--:rules="rulesSlider"-->
                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                    <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft">
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.galeri.define.header")' prop="anaBaslik">:
                                    <el-input size="small" v-model="galeriForm.anaBaslik" :placeholder='$t("src.views.apps.galeri.define.headerPlace")'></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" >
                                <Resim functionName="galeriTanimlaImage" :selectedsFromServer="selectedImagesFromServer"></Resim>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>


                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
                        <el-collapse-item name="1" :title='$t("src.views.apps.slider.tanimlama.durum")'>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item prop="durum">
                                        <el-select size="small" v-model="galeriForm.durum" style="width: 100%">
                                            <el-option value="0" :label='$t("src.views.apps.genel.taslak")'>{{$t("src.views.apps.genel.taslak")}}</el-option>
                                            <el-option value="1" :label='$t("src.views.apps.genel.aktif")'>{{$t("src.views.apps.genel.aktif")}}</el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-button size="mini" v-on:click="galeriTanimla('galeriForm',galeriForm.durum)" class="onayBtn" type="primary">
                                    <label v-if="galeriForm.durum === '1'">{{$t('src.views.apps.galeri.define.active')}}</label>
                                    <label v-else>{{$t('src.views.apps.genel.taslakKaydet')}}</label>
                                </el-button>
                                &nbsp;
                                <el-popover placement="bottom" width="300" v-model="visibleSil">
                                    <p>{{$t('src.views.apps.messages.clearFormAlert')}}</p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="primary" @click="resetForm('galeriForm')">
                                            {{$t('src.views.apps.genel.yes')}}
                                        </el-button>
                                        <el-button size="mini" @click="visibleSil = false;">
                                            {{$t('src.views.apps.genel.no')}}
                                        </el-button>
                                    </div>
                                    <el-button size="mini" slot="reference">
                                        {{$t('src.views.apps.genel.temizle')}}
                                    </el-button>
                                </el-popover>
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>

<script>
    import galeriService from '../../../WSProvider/GaleriService';
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'
    import Resim from '@/components/Resim.vue'

    export default {
        name: "GaleriTanimla",
        // galeri tanımlarken birden fazla resim eklenebiliyor. Ama güncellerken tek resim güncelleniyor.
        components: {
            Resim 
        },
        data() {
            return {
                selectedImagesFromServer: [],
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                // Form
                loading: false,
                visibleSil: false,
                galeriForm: {
                    //sayfaLink: "",
                    anaBaslik: "",
                    altBaslik: "",
                    resim: [],
                    durum: "1",
                },
                
                rulesGaleri: {
                    anaBaslik: [{
                        required: true,
                        message: "Başlık Giriniz",
                        trigger: 'blur'
                    }],/*
                    sayfaLink: [{
                        required: true,
                        message: $t('src.views.apps.genel.selectPage'),
                        trigger: 'blur'
                    }],*/
                    resim: [{
                        required: true,
                        message: "Resim Seçiniz",
                        trigger: ['blur', 'trigger']
                    }],
                },
            }
        },
        mounted() {
            const self = this;
            EventBus.$on("galeriTanimlaImage", function(bool, imageArray) {
                if (bool) {
                    self.galeriForm.resim = imageArray;
                }
            });
        },
        methods: {
            galeriTanimla(formName, durum) {
                if(this.validateForm(formName)){
                    this.openConfirmDialog(this.$t('src.views.apps.messages.addImageConfirm')).then(() => {
                        try{
                            this.loading = true;
                            galeriService.galeriEkle(this.galeriForm.anaBaslik, this.galeriForm.resim, durum).then((response) => {
                                if (response.status == 200) {
                                    localStorage.setItem("userDataDemirag", response.token)
                                    this.resetForm(formName);
                                    EventBus.$emit("galeriList", true)
                                    this.sayfaKapat('galeritanimla')
                                }
                                notification.Status('success', this, response.msg);
                                this.loading = false;
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.loading = false;
                            });
                        }catch(e){
                            this.loading = false;
                        }
                    })
                }
            },
            
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.visibleSil = false;
            },
        }
    }

</script>

<style lang="scss" scoped>
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }

    .cop {
        color: #ec205f;
        position: relative;
        float: right;
        right: 15px;
        bottom: 10px;
        font-size: 20px;
    }

    .yanForm .card {
        margin-top: 10px;
    }
</style>